:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1300px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    /*Colors*/
    --text-color: #303030;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #39959a;
    --effect-color: #93cfd2;
    --header-text-color: --text-color;
    --header-bg-color: #fff;
    --footer-bg-color: #8b2f92;
    --footer-text-color: #fff;
    --menu-text-color: #a7509e;
    --menu-text-hover-color: var(--text-color);  
    /*Fonts*/
    --base-font-family: "Lato", "Helvetica", "Arial", sans-serif;
    --alternative-font-family: "Lato", "Helvetica", "Arial", sans-serif;
}


@import "/fontmodules/Lato.css";
@import "/fontmodules/Lato.css";

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/

hr {
    color: #ececec;
}

.refbild {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.lista img {
    float: right;
    max-width: 60%;
    margin: 0 0 15px 15px;
}

.slickwrapper {
    position: fixed;
    width: 100%;
    top: 0px;
}

.hr .lista  ul {
    margin-bottom: 2vh;
    float: left;
}
.hr .lista  ul li {
    list-style-type: none;
    width: 32%; 
    float: left;
}

.hr .lista ul li:before {
    content: "\2714";
    margin: 0 .3em 0 0;
    font-size: 1.2em;
    color: #a7509e;
}
.referenser .lista  ul li {
    list-style-type: none;
}

.referenser .lista ul li:before {
    content: "\2714";
    margin: 0 .3em 0 0;
    font-size: 1.2em;
    color: #a7509e;
}
.utbildning .lista  ul li {
    list-style-type: none;
}

.utbildning .lista ul li:before {
    content: "\2714";
    margin: 0 .3em 0 0;
    font-size: 1.2em;
    color: #a7509e;
}

.hr .sidatext, .hr .sidabild {
    display: none;
}


.homepage h1 {
    font-size: 4rem;
    margin-bottom: 0px;
}
.starttext {
    position: absolute;
    top: 0;
    transform: translateY(30vh);
    right: 0;
    left: 0;
}
.starttext p {
    font-size: 1.5em;
    color: #fff;

}


.startcontent {
    position: relative;
    z-index: 2;
    margin-top: 79vh;
    background: rgba(255, 255, 255, 0.9);
}
.startcontentpage  {
    position: relative;
    z-index: 2;
    margin-top: 70vh;
    background: rgba(255, 255, 255, 0.9);
}
/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/

body {
    font-family: var(--base-font-family);
    line-height: 1.5;
    font-weight: 400;
    color: var(--text-color);
    background: var(--body-bg);
}
p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
}

.imagetop {
        margin-bottom: 0;
    top: 0;
    min-height: 100vh;
    position: fixed;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 0;
}

figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.button {
    margin: 1rem 0px;
    background: #F2F2F2;
    border: none;
    transition: background 1s;
}

.button:hover {
    background: #E3E3E3;
}

/* ------------------------------- FONTS + HEADINGS ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    margin: 0.5em 0; 
    font-weight: 900;
    color:#8b2f92;
    font-style: italic;
}
.slick-title {
    font-size: 1.6rem;
    font-family: var(--alternative-font-family);
    font-weight: 600;
    text-align: center;
}

/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/* ---------------SLIDER----------- */
.slick {
    margin-bottom: 0;
    
}
.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;
    
} 
.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    display: flex;
    background-size: cover;
    background-position: center top;
}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* ----------------STARTTEXT------------  */
.starttext {
    text-align: center;
    padding: 15px 0 0px 0;
}

/* ------------------STARTBOXARNA----------- */
.boxwrapper {
    min-height: 24vh;
    background-size: 0;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption  {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.boxwrapper[style]:before  {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .3s ease-in 0s;
}

.boxwrapper figure img {

 transition: transform .3s ease-in 0s;
}

.boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .3s ease-in 0s;
    z-index: 2;
}


.boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .8s ease-in 0s;

}

.boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .8s ease-in 0s;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    padding: 0.8rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px rgba(0,0,0,.5), 2px 2px 6px rgba(0,0,0,.3);
    font-size: 2.2rem;
    z-index: 3;
}

/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */

.artikelbild {
    float: right;
    max-width: 40%;
    height: auto;
    margin: 0 0 15px 15px
}
.newsitem hr {
    color: #efefef;
}
a.readmore {
    color: #fff;
}
.readmore{background: #8b2f92; padding:10px; border-radius:5px; color:#fff; margin:0;}

/*------------------------------------------------------------------------------------------------ 
General content--------------------------------------------------------------------------------*/

/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #303030;
}


/* -------------- logo gallery ------------ */
.logotype-gallery {
    padding: 2em 0;
}
.logotype-image {
    min-height: 70px;
} 
.logotype-image div, .logotype-image a {
    text-align: center; 
    width: 100%; 
    flex:0 0 auto; 
    display: block;
} 
.logotype-image img {
    height: auto;
} 
.standing  {
    width:40%; 
} 
.squarish  {
    width:40%; 
} 
.landscape  {
    width:55%; 
}
.longlandscape {
    width:70%; 
}
.extralonglandscape {
    width:100%; 
}

/* -------------- mfp-ajax ------------ */

.mfp-ajax-holder .wrapper{
    min-height: auto;
}

.mainAjaxContent :first-child{
    margin-top: 0;
}

.mfp-ajax-holder{
    background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    height: calc(100% - 80px);
    overflow-y: auto;
}

.mfp-close {
    margin: 0;
}


/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */
header {
    background: rgba(255, 255, 255, 0.9);
    z-index: 10;
    width: 100%;
    position: fixed; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px;
}
.logo {
    padding: 10px;
}
.logo img {
    max-height: 50px; 
    width: auto; 
}
.logo a, .logo img {
    display: block;
}
header nav {}
header nav ul.menu {}
header nav ul.menu li {}
header nav ul.menu li a {
    color: var(--menu-text-color);
    font-size: 1.1em;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
}
.menu.menu li a:hover, .menu.menu li.active a {
    color: var(--menu-text-hover-color);
}

/*
nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; }
*/

/*----------------------------MOBILMENY----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
        header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
    }


}
    
    

/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: var(--footer-bg-color);
    padding: 15px 0;
    position: relative;
    z-index: 2;
    text-align: center;
}
footer, footer a, footer a:hover { 
    color: var(--footer-text-color); 
}
footer svg {
    margin-right: 10px;
    fill: var(--footer-text-color);
}
footer h4 {
    margin-top:0;
}

/* -----------------Intendit-footer---------------- */
.created-by {
    background: var(--footer-bg-color);
    text-align: center;
    padding: 10px 0; 
    position: relative;
    z-index: 2;
    font-size: 0.8em;
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: var(--footer-text-color);
    line-height:1;
}
p.intenditfooter {
   margin:0;
}
/* -----------------KONTAKT---------------- */


/* -----------------KONTAKTFORMULÄR---------------- */

.boltform form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    z-index: 2;
}

.boltforms-row {
    flex-basis: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.boltforms-six-row {
    flex-basis: 50%;
}

.boltforms-four-row {
    flex-basis: 33%;
}

.boltforms-three-row {
    flex-basis: 25%;
}

.boltform textarea, .boltform input {
    width: 100%;
}

@media (max-width: var(--breakpoint-s))
{
    .boltforms-row {
        flex-basis: auto;
    }
    
    .boltform form {
        flex-direction: column;
    }
    .starttext p {
        font-size: 1.2em;

    }
    .homepage h1 {
        font-size: 3rem;
    }
    .starttext {
        transform: translateY(30%);
    }
    .logo img {
        max-height: 30px;
    }
}

.formwrapper {
    background-color: #ececec; 
    padding: 20px 0px;
    position: relative;
    z-index: 2;
}

.boltforms-row button {
    margin: 0 auto;
    display: block;
    border: none;
    background: #d0d0d0;
    color: #303030;
    transition: background 0.5s ease;
}
.boltforms-row button:hover {
    color: #303030;
    background: #b3b3b3;

}
/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 35vh;
}

.map-canvas img {
    max-width: none;
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under mobilmeny----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    
}

/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 
    
    .formwrapper {
        padding-top: 1vh;
    }
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: 1450px)
{
    header nav ul.menu li a {
        font-size: 1em;
    }
}
@media (max-width: 1400px)
{
    header nav ul.menu li a {
        font-size: 0.9em;
    }
}
/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
   
    .container {
        width: 96%; 
    } 
    
  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    .container.sml {
        width: 96%; 
    } 
    
    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 
    .slickwrapper {
        position: relative;
    }
    .homepage .startcontent {
        margin-top: 0px;
    }
    .lista img {
        float: right;
        max-width: 100%;
        margin: 0 0 15px 15px;
    }

   

    .hr .lista  ul {
        margin-bottom: 2vh;
        float: left;
    }
    .hr .lista  ul li {
        list-style-type: none;
        width: 100%; 
        float: left;
    }
    .refbild {
        min-height: 10vh;
    }
    .artikelbild {
    float: none;
    max-width: 100%;
    height: auto;
}


}

ul.sub-menu-items {
    background: #e5e5e5 !important;
    border: 1px solid #d9d9d9;
    transition:.5s;
}

.banner-wrapper.flex.margin {
    position: relative;
    bottom: 0;
    background: #fff;
}
.banner-wrapper .banner-content{
    max-width:800px;
    margin: 0 auto;
    text-align:center;
    padding:1.5rem;
}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    
}
